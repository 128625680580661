import React, { lazy, useEffect, useState } from "react";

//** Load App
const LazyApp = lazy(() => import("./App"));

/*Hooks*/
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

/*Redux*/
import * as actionsCommon from "@store/common/actions";
import { getLanguage } from "@store/common/selectors";
import BackdropPressable from "./components/BackdropPressable";

const InitApp = ({ DefaultRoute, listRoutes, listNav }) => {
	const dispatch = useDispatch();
	const { i18n } = useTranslation();
	const [loaded, setLoaded] = useState(false);

	/*Selectors*/
	const lang = useSelector((state) => getLanguage(state));

	useEffect(() => {
		i18n.changeLanguage(lang);
	}, [lang]);

	useEffect(() => {
		const setupInitApp = async () => {
			setLoaded(true);
		};
		setupInitApp().then();
	}, []);
	return (
		<React.Fragment>
			<LazyApp DefaultRoute={DefaultRoute} Routes={listRoutes} Nav={listNav} />
			<BackdropPressable />
		</React.Fragment>
	);
};
export default InitApp;
