import { ENVIRONMENT_STNHD_BASE_URL_API, ENV_ENVIRONMENT_BASE_URL_API, ENV_ENVIRONMENT_BASE_URL_API_PAYMENT } from "./environment";
export const BASE_URL_API = ENV_ENVIRONMENT_BASE_URL_API;
export const BASE_AGENCY_API_URL = ENV_ENVIRONMENT_BASE_URL_API_PAYMENT;
export const BASE_STNHD_BASE_URL_API = ENVIRONMENT_STNHD_BASE_URL_API;
export const BASE_URL_API_STNHD = ENVIRONMENT_STNHD_BASE_URL_API;
export default {
  /*Common*/
  GET_COMMON_GET_CITY: "/Province",
  GET_COMMON_GET_DISTRICT: "/District",
  GET_LIST_CLASS: "/SchoolClass",
  GET_SHARE_INFO: "WebsiteSTNHD/ShareInfo",
  /*End Common*/
  SYNC_INFO: "/MstUser/SyncInfo",
  POST_SEND_OTP: "/SieuTriNhoHocDuong/SendOTP",
  POST_AUTH_FORGOT_PASSWORD: "/AccountHelper/ForgetPassword",
  SEND_OTP_FOGET_PASSWORD: "/AccountHelper/OTPConfirmPassword",
  POST_FORGET_USERNAME: "/AccountHelper/ForgetUsername",
  SEND_OTP_FORGET_USERNAME: "/AccountHelper/OTPConfirmAccount",

  GET_HOME_PAGE_LANG: "/MstHomePage/listfilter",
  GET_IP: "/cdn-cgi/trace",
};
