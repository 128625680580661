export default {
	INVOICE_CREATE: "/VMM/InvoiceCreate",
	// INVOICE_INFO: "/SieuTriNhoHocDuong/InvoiceInfo",
	INVOICE_UPDATE: "/VMM/InvoiceUpdate",
	GET_ONEPAY_RESPONSE_INFO: "/VMM/ResponeOnepay",
	GET_MOMO_RESPONSE_INFO: "/VMM/ResponeMoMo",
	GET_ZALOPAY_RESPONSE_INFO: "/VMM/ResponeZaloPay",
	UPDATE_INVOICE: "/VMM/UpdateStatus",
	POST_GET_LIST_PACKAGE: "/VMM/SalesPrice",
	POST_ACTIVE_CODE: "/VMM/ActiveCode",
	GET_VNPAY_RESPONSE_INFO: "/VMM/ResponeVNPay",
	GET_VIETTELPAY_RESPONSE_INFO: "/VMM/ResponeViettelPay",
	GET_PAYPAL_RESPONSE_INFO: "/VMM/ResponePaypal",
};
