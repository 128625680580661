import * as Actions from './constants';
import { getShareInfoData } from './services';
import { put, call, takeEvery, select } from 'redux-saga/effects';
import { getCodeLanguage } from '@store/common/selectors';

function* getDataShareInfo() {
  try {
    const codeLanguage = yield select(getCodeLanguage);
    const res = yield call(getShareInfoData, codeLanguage);
    yield put({ type: Actions.GET_SHARE_INFO_SUCCESS, payload: res });
  } catch (e) {
    yield put({ type: Actions.GET_SHARE_INFO_FAILED, error: e });
  }
}

export default function* shareInfoSaga() {
  // yield takeEvery(Actions.GET_SHARE_INFO_REQUEST, getDataShareInfo);
}
