import intlMessagesEN from "./i18n/localization/en.json";
import intlMessagesVi from "./i18n/localization/vi.json";
// import initReducer from './Store/initReducer';

export default {
  name: "Social",
  dir: "Social",
  pathRoot: "social",
  routes: [
    {
      url: "personal/:userId",
      component: "Page/Personal",
      meta: {
        authRoute: true,
      },
      props: {
        title: "VMindMap - Tất cả bắt đầu bằng một ý tưởng",
        titleI18n: "Social:title",
      },
    },
    {
      url: ":id",
      component: "Page/Detail",
      meta: {
        authRoute: true,
      },
      props: {
        title: "VMindMap - Tất cả bắt đầu bằng một ý tưởng",
        titleI18n: "Social:title",
      },
    },
    {
      url: "",
      component: "Page/index",
      meta: {
        authRoute: true,
      },
      props: {
        title: "VMindMap - Tất cả bắt đầu bằng một ý tưởng",
        titleI18n: "Social:title",
      },
    },
  ],
  lang: { vi: intlMessagesVi, en: intlMessagesEN },
  isAuthenticate: "Any",
  // redux: initReducer,
};
