import API from "../../configs/api";
import apiMethod from "@utility/ApiMethod";
import apiMethodSTNHD from "@utility/ApiMethodSTNHD";

export const getInfoUser = ({ ...payload }) => {
  return apiMethod.post(API.GET_PROFILE, payload);
};

export const updateUserInfo = (payload) => {
  return apiMethod.put(API.UPDATE_USER_INFO, payload);
};

export const getGeneralInfoMenu = ({ payload, codeLanguage }) => {
  return apiMethod.get(codeLanguage + API.GET_GENERAL_INFO_MENU, payload);
};
