import intlMessagesEN from "./i18n/localization/en.json";
import intlMessagesVi from "./i18n/localization/vi.json";
// import initReducer from './Store/initReducer';

export default {
  name: "ComingSoon",
  dir: "ComingSoon",
  pathRoot: "coming-soon",
  routes: [
    {
      url: "",
      component: "Page/index",
      meta: {
        authRoute: true,
      },
      props: {
        title: "VMindMap - Tất cả bắt đầu bằng một ý tưởng",
        titleI18n: "ComingSoon:title",
      },
    },
  ],
  lang: { vi: intlMessagesVi, en: intlMessagesEN },
  isAuthenticate: "Any",
  // redux: initReducer,
};
