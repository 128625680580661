import intlMessagesEN from "./i18n/localization/en.json";
import intlMessagesVi from "./i18n/localization/vi.json";
// import initReducer from './Store/initReducer';

export default {
  name: "Helper",
  dir: "Helper",
  pathRoot: "helper",
  routes: [
    {
      url: "license",
      component: "Page/License",
      meta: {
        authRoute: true,
      },
      props: {
        title: "Giấy phép đào tạo | VMindmap",
        titleI18n: "Helper:titleLicense",
      },
    },
    {
      url: "payment-term",
      component: "Page/Payment",
      meta: {
        authRoute: true,
      },
      props: {
        title: "Điều khoản thanh toán | VMindmap",
        titleI18n: "Helper:titlePayment",
      },
    },
    {
      url: "buy-guide",
      component: "Page/BuyGuide",
      meta: {
        authRoute: true,
      },
      props: {
        title: "Hướng dẫn mua hàng | VMindmap",
        titleI18n: "Helper:titleBuy",
      },
    },

    {
      url: "private-policy",
      component: "Page/Privatev2",
      meta: {
        authRoute: true,
      },
      props: {
        title: "Chính sách bảo mật | VMindmap",
        titleI18n: "Helper:titlePrivate",
      },
    },
    {
      url: "complain-policy",
      component: "Page/Complain",
      meta: {
        authRoute: true,
      },
      props: {
        title: "Chính sách khiếu nại | VMindmap",
        titleI18n: "Helper:titleComplain",
      },
    },
    {
      url: "return-policy",
      component: "Page/Return",
      meta: {
        authRoute: true,
      },
      props: {
        title: "Chính sách hoàn trả | VMindmap",
        titleI18n: "Helper:titleReturn",
      },
    },
    {
      url: "private",
      component: "Page/Private",
      meta: {
        authRoute: true,
      },
      props: {
        title: "VMindMap - Tất cả bắt đầu bằng một ý tưởng",
        titleI18n: "Helper:titlePrivateTab",
      },
    },
    {
      url: "faq",
      component: "Page/Faq",
      meta: {
        authRoute: true,
      },
      props: {
        title: "VMindMap - Tất cả bắt đầu bằng một ý tưởng",
        titleI18n: "Helper:titleFaqTab",
      },
    },
    {
      url: "",
      component: "Page/index",
      meta: {
        authRoute: true,
      },
      props: {
        title: "VMindMap - Tất cả bắt đầu bằng một ý tưởng",
        titleI18n: "Helper:title",
      },
    },
  ],
  lang: { vi: intlMessagesVi, en: intlMessagesEN },
  isAuthenticate: "Any",
  // redux: initReducer,
};
