export default {
  AUTH_LOGIN: "/UserInfo/login",
  LOGIN_SOCIAL: "/MstUser/Login-Social",
  POST_CONFIRM_OTP: "/SieuTriNhoHocDuong/OTPConfirm",
  POST_REGISTER_DAISUSTNHD: "/SieuTriNhoHocDuong/RegisterWithOTP",
  GET_PROFILE_STNHD: "/UserInfo/GetProfile",

  SYNC_INFO: "/MstUser/SyncInfo",
  GET_USER_INFO: "/MstUser/GetProfile",
  UPDATE_FLAG_VIEW_TUTORIAL: "/MstUser/update-flagView",
  CHECK_EMAIL_IN_SYSTEM: "/SieuTriNhoHocDuong/CheckEmailInSystem",
  POST_SIGN_UP_TRIAL_ACCOUNT: "/SieuTriNhoHocDuong/Register",
};
