const ENV_ENVIRONMENT_awsKeys = {
  accessKeyID: "RlK3uGBY38mfev2lFGGv",
  secretKeyID: "dZnBmWg2Qh2UPA5OGPUfRfrGjIvKsokVlh7oXCVZ",
  endpoint: "https://s3.5ptb.com",
  bucket: "5ptb",
};

export const ENV_ENVIRONMENT_BASE_URL_API = "https://apiv2.vmindmap.com.vn/api/";
export const ENV_ENVIRONMENT_BASE_URL_API_PAYMENT =
  "https://apiagencyv2.vmindmap.com.vn/api";

export const ENV_ENVIROMENT_BASE_URL = "https://app.vmindmap.com.vn";
export const ENV_ENVIROMENT_BASE_APP_URL = "https://app.vmindmap.com.vn";

export const KEY_MAP = "AIzaSyAP2EDHv1qETgUfjOys1ov_PlAAUt-H05Y";

export const ENVIRONMENT_awsKeys = ENV_ENVIRONMENT_awsKeys;
export const ENVIRONMENT_STNHD_BASE_URL_API =
  "https://apiptb.5ptb.com/api";
