import intlMessagesEN from "./i18n/localization/en.json";
import intlMessagesVi from "./i18n/localization/vi.json";
import initReducer from "./Store/initReducer";
import initSagas from "./Store/initSagas";

export default {
	name: "PackagePrice",
	dir: "PackagePrice",
	pathRoot: "package-price",
	routes: [
		{
			url: "",
			component: "Page/index",
			meta: {
				authRoute: true,
			},
			props: {
				title: "Trang chủ | VMindmap",
				titleI18n: "payment:title",
				headerStyle: "fill",
				exact: true,
			},
		},
		// {
		// 	url: "payment",
		// 	component: "Page/Payment",
		// 	meta: {
		// 		authRoute: true,
		// 	},
		// 	props: {
		// 		title: "Về chúng tôi | VMindmap",
		// 		titleI18n: "home:title",
		// 		headerStyle: "fill",
		// 		exact: true,
		// 	},
		// },
	],
	lang: { vi: intlMessagesVi, en: intlMessagesEN },
	isAuthenticate: "Any",
	redux: initReducer,
	sagas: initSagas,
};
