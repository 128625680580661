export const logout = () => {
  // localStorage.clear();

  Object.entries(localStorage).forEach(([key, val]) => {
    if (!key.includes("persist:common")) {
      localStorage.removeItem(key);
    }
  });
  window.location.replace(window.location.origin);
  return true;
};

export const setToken = (token) => {
  localStorage.setItem("token", token);
};

export const setAccessToken = (token) => {
  localStorage.setItem("accessToken", token);
};

export const setUserInfo = (user) => {
  localStorage.setItem("user_info", JSON.stringify(user));
};
